var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CCollapse',{attrs:{"show":!_vm.collapseBlocks}},[_c('loading-overlay',{attrs:{"active":_vm.Loading,"is-full-page":true,"loader":"bars"}}),_c('BlocksBaysModal',{attrs:{"modal":_vm.modal,"BlockBaysItems":_vm.BlockBaysItems,"edit":_vm.edit,"title":_vm.titleModal},on:{"update:modal":function($event){_vm.modal=$event},"submited":_vm.handleSubmit,"close":_vm.closeModal}}),_c('CRow',[_c('CCol',{staticClass:"mt-3 d-flex justify-content-start px-0",attrs:{"sm":"12","lg":"10"}},[_c('CSelect',{staticClass:"col-sm-9",attrs:{"label":_vm.$t('label.subclassification'),"horizontal":{label: 'col-sm-12 col-lg-auto', input:'col-sm-12 col-lg-6'},"options":_vm.SudClassificationOptions,"add-label-classes":"text-left"},on:{"change":function($event){return _vm.changeSubclasification($event)}},model:{value:(_vm.YardSubclasificationId),callback:function ($$v) {_vm.YardSubclasificationId=$$v},expression:"YardSubclasificationId"}})],1),_c('CCol',{staticClass:"d-flex justify-content-end align-items-center",attrs:{"sm":"12","lg":"2"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
              content: _vm.$t('label.blocksBays'),
              placement: 'top-end'
            }),expression:"{\n              content: $t('label.blocksBays'),\n              placement: 'top-end'\n            }"}],staticClass:"d-flex align-items-center",attrs:{"color":"add"},on:{"click":_vm.toggleAdd}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('label.nuevo')))])],1)],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"items":_vm.formatedItems,"fields":_vm.fields,"column-filter":"","noItemsView":_vm.tableText.noItemsViewText,"table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"items-per-page":_vm.tableText.itemsPerPage,"hover":"","small":"","sorter":"","pagination":""},scopedSlots:_vm._u([{key:"Status",fn:function({ item }){return [_c('td',{staticClass:"text-center align-middle"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"Detalle",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  content: _vm.$t('label.edit')+' '+_vm.$t('label.blocksBays'),
                  placement: 'top-end'
                }),expression:"{\n                  content: $t('label.edit')+' '+$t('label.blocksBays'),\n                  placement: 'top-end'\n                }"}],staticClass:"mr-1",attrs:{"color":"edit","size":"sm"},on:{"click":function($event){return _vm.toggleEdit(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  content: _vm.$t('label.see')+_vm.$t('label.details'),
                  placement: 'top-end'
                }),expression:"{\n                  content: $t('label.see')+$t('label.details'),\n                  placement: 'top-end'\n                }"}],staticClass:"mr-1",attrs:{"color":"watch","size":"sm"},on:{"click":function($event){return _vm.viewDetails(item)}}},[_c('CIcon',{attrs:{"name":"cil-list"}})],1),(_vm.disabledReportBottom)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  content: _vm.$t('label.generateReport'),
                  placement: 'top-end'
                }),expression:"{\n                  content: $t('label.generateReport'),\n                  placement: 'top-end'\n                }"}],staticClass:"mr-1",attrs:{"color":"danger","size":"sm"}},[_c('CIcon',{attrs:{"name":"cil-file-pdf"}})],1):_vm._e()],1)]}}])})],1)],1)],1),_c('CCollapse',{attrs:{"show":_vm.collapseBlocks}},[(_vm.collapseBlocks)?_c('div',[_c('MenuBlocks',{attrs:{"titleHeight":_vm.titleHeight}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }